<template>
  <div style="margin: 20px">
    <label style="line-height: 40px">За период</label>
    <el-select
      v-model="params.period_id"
      placeholder="Выберите период"
      style="margin-left: 20px"
      @change="fetchData">
      <el-option
        v-for="item in periods"
        :key="item.id"
        :label="item.title"
        :value="item.id">
      </el-option>
    </el-select>
    <label style="line-height: 40px; margin-left: 20px">По вице-губернатору</label>
    <el-select
      v-model="params.vice_governor_id"
      placeholder="Выберите вице-губернатора"
      clearable
      style="margin-left: 20px; width: 250px"
      @change="fetchData">
      <el-option
        v-for="item in viceOptions"
        :key="item.id"
        :label="item.title"
        :value="item.id">
      </el-option>
    </el-select>
    <el-checkbox
      v-model="params.vice_grouping"
      style="float: right; line-height: 40px"
      @change="fetchData">
      Группировка по вице-губернаторам
    </el-checkbox>
    <el-divider/>
    <div v-loading="loading">
        <div style="text-align: right; margin-bottom: 20px">
          <el-button @click="downloadExportKP" v-loading="disableExportBtn"><i class="fas fa-download" style="margin-right: 5px"/>Скачать выгрузку</el-button>
          <el-button v-if="userCan(['technologist', 'control', 'admin', 'vice_governor', 'observer'])" @click="downloadPrintKP" v-loading="disablePrintBtn"><i class="fas fa-download" style="margin-right: 5px"/>Скачать доклад</el-button>
          <el-button v-if="userCan(['technologist', 'control', 'admin'])" @click="$router.push({ name: '/kp-result/history', query: { period_id: params.period_id } })"><i class="fas fa-history" style="margin-right: 5px"/>Историчность</el-button>
        </div>
        <k-p-result-table
          :k-p-data="kpdata" name="kpresults"
          :vice-governors="viceGovernors"
          :need-ebosp-comment.sync="params.need_ebosp_comment"
          @checkbox-change="checkboxChange"/>
      </div>
  </div>
</template>

<script>
import KPResultTable from '@/components/KPResultTable'
import { getKPResult } from '@/api/api'
import { getViceGovernorsDict, getPeriods } from '@/api/catalogs'
import { getExportKP, getPrintKP } from '@/api/file'
import { saveFile } from '@/utils'
import userCan from '@/utils/checkPermission'

export default {
  components: {
    KPResultTable
  },
  data () {
    return {
      viceOptions: [],
      params: {
        period_id: null,
        vice_governor_id: null,
        need_ebosp_comment: null,
        vice_grouping: null
      },
      periods: [],
      viceGovernors: [],
      loading: true,
      sources: [],
      iogv: [],
      kpdata: {},
      disableExportBtn: false,
      disablePrintBtn: false
    }
  },
  beforeMount () {
    getPeriods({ is_monthly: true, started: true }).then((response) => {
      this.periods = response.data.data
    })
    if (this.$route.query.need_ebosp_comment !== undefined) {
      this.params.need_ebosp_comment = (this.$route.query.need_ebosp_comment.includes('true'))
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    checkboxChange (val) {
      this.params.need_ebosp_comment = val
      this.fetchData()
    },
    userCan: (roles) => userCan(roles),
    fetchData () {
      this.loading = true
      getKPResult(this.params).then(response => {
        this.kpdata = response.data
        this.params = response.data.searchModel
        this.viceGovernors = response.data.vices
        getViceGovernorsDict({ period_id: this.params.period_id }).then((response) => {
          this.viceOptions = response.data.data
        })
        this.loading = false
      })
    },
    downloadExportKP () {
      this.disableExportBtn = true
      getExportKP(this.params).then(response => {
        const fileName = response.headers['content-disposition'].split('filename*=utf-8\'\'')[1].split(';')[0]
        saveFile(response.data, decodeURI(fileName))
        this.disableExportBtn = false
      })
    },
    downloadPrintKP () {
      this.disablePrintBtn = true
      getPrintKP(this.params).then(response => {
        const fileName = response.headers['content-disposition'].split('filename*=utf-8\'\'')[1].split(';')[0]
        saveFile(response.data, decodeURI(fileName))
        this.disablePrintBtn = false
      })
    }
  }
}

</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.desktop-cols {
  padding: 5px;
}

.chart {
  z-index: 100;
}
</style>
