<template>
  <div>
    <el-row>
      <el-col :span="12">
        <div  class="length-text">
          <span>Показано {{ list.length }} {{ getSpell(list.length)}}</span>
        </div>
      </el-col>
      <el-col :span="12" style="text-align: right" v-if="userCan(['operator']) && $store.state.user.tempRoutes.KpStorageCommentInput">
        <el-checkbox v-model="needEbospComment" @change="(val) => $emit('checkbox-change', val)">Отображать только показатели, по которым требуется добавить комментарий и файл</el-checkbox>
      </el-col>
    </el-row>
    <el-table
      v-if="showTable"
      ref="table"
      :data="list"
      border
      fit
      size="mini"
      class="main-table"
      max-height="800"
      @header-dragend="handleWidth"
      :row-class-name="getRowClass"
      :cell-class-name="getRatingClass"
      :tree-props="{ children: 'pkis' }"
      row-key="sort_field">
      <el-table-column
        fixed
        prop="pki_id"
        label="Индекс КПР"
        width="70px">
      </el-table-column>
      <el-table-column
        fixed
        prop="name"
        label="Название"
        width="300px">
      </el-table-column>
      <el-table-column
        fixed
        prop="formula"
        label="Расчёт"
        width="200px">
        <template slot-scope="scope">
          <katex-element v-if="scope.row.formula.indexOf('`') >= 0" :expression="getFormula(scope.row.formula)"/>
          <span v-else>{{ scope.row.formula }}</span>
        </template>
      </el-table-column>
      <template v-if="groupVice">
        <el-table-column
          v-for="vice in ViceGovernors"
          :key="vice.id"
          :label="vice.title"
          align="center">
          <el-table-column
            v-for="item in iogvs.filter(x => x.vice_governor_id === vice.id)"
            :key="item.id"
            :label="item.short_name"
            :prop="'iogv'+item.id"
            width="130px"
            :column-key="item.id.toString()">
            <template slot-scope="scope">
              <div :class="getRatingClass(scope.row, item.id)" style="text-align: center">
                <span>{{ getRating(scope.row, item.id) }}</span>
                <br>
                <i class="fas fa-eye" v-if="canView(scope.row, item.id)"></i>
                <el-tooltip
                  v-if="getOperatorComment(scope.row, item.id) || getEbospComment(scope.row, item.id)"
                  class="item"
                  effect="dark"
                  placement="top">
                  <div slot="content">
                    {{ getOperatorComment(scope.row, item.id) }}
                    <br/>
                    {{ getEbospComment(scope.row, item.id) }}
                  </div>
                  <a><i class="far fa-comment-alt"></i></a>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </template>
      <template v-else>
        <el-table-column
          v-for="item in iogvs"
          :key="item.id"
          :label="item.short_name"
          :prop="'iogv'+item.id"
          width="130px"
          :column-key="item.id.toString()">
          <template slot-scope="scope">
            <div class="rating-cell">
              <span>{{ getRating(scope.row, item.id) }}</span>
              <br>
              <a
                v-if="canEdit(scope.row, item.id) || canEditComment(scope.row, item.id)"
                @click="$router.push({name: '/kp-storage/update', params: {id: getKpId(scope.row, item.id)}})">
                <i class="fas fa-edit"/>
              </a>
              <a
                v-if="canView(scope.row, item.id)"
                @click="$router.push({name: '/kp-storage/view', params: {id: getKpId(scope.row, item.id)}})">
                <i class="fas fa-eye"/>
              </a>
              <el-tooltip
                v-if="getOperatorComment(scope.row, item.id) || getEbospComment(scope.row, item.id)"
                class="item"
                effect="dark"
                placement="top">
                <div slot="content">
                  {{ getOperatorComment(scope.row, item.id) }}
                  <br/>
                  {{ getEbospComment(scope.row, item.id) }}
                </div>
                <a><i class="far fa-comment-alt"></i></a>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
import AsciiMathParser from 'asciimath2tex'
import userCan from '@/utils/checkPermission'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    KPData: {
      type: Object,
      required: true
    },
    needEbospComment: {
      default: false
    },
    ViceGovernors: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      showTable: true
    }
  },
  computed: {
    list () {
      return this.KPData.blocks ?? []
    },
    groupVice () {
      return this.KPData.searchModel?.vice_grouping
    },
    iogvs () {
      return this.KPData.iogvs ?? []
    }
  },
  watch: {
    KPData: {
      deep: true,
      handler () {
        this.showTable = false
        this.$nextTick(() => {
          this.showTable = true
        })
      }
    }
  },
  methods: {
    userCan,
    getSpell (val) {
      if (val % 100 < 20 && val % 100 > 10) {
        return 'записей'
      }
      if (val % 10 === 1) {
        return 'запись'
      } else if (val % 10 < 5 && val % 10 > 1) {
        return 'записи'
      }
      return 'записей'
    },
    getRating (row, id) {
      return row.rating_data[id]?.rating
    },
    getKpId (row, id) {
      return row.rating_data[id]?.id
    },
    canView (row, id) {
      return row.rating_data[id]?.can_view
    },
    canEdit (row, id) {
      return row.rating_data[id]?.can_edit
    },
    canEditComment (row, id) {
      return row.rating_data[id]?.can_edit_comment
    },
    getOperatorComment (row, id) {
      return row.rating_data[id]?.reason_by_operator
    },
    getEbospComment (row, id) {
      return row.rating_data[id]?.reason_by_ebosp
    },
    getRowClass ({ row }) {
      if (row.pkis) {
        return 'row-bold'
      }
    },
    getRatingClass ({ row, column }) {
      if (column?.property?.indexOf('iogv') === 0) {
        const classIndex = row.rating_data[column.columnKey]?.class_index
        switch (classIndex) {
          case -1: {
            return ''
          }
          case -2: {
            return 'comment-exist'
          }
          case 1: {
            return 'rating-e'
          }
          case 2: {
            return 'rating-d'
          }
          case 3: {
            return 'rating-c'
          }
          case 4: {
            return 'rating-b'
          }
          case 5: {
            return 'rating-a'
          }
        }
      }
    },
    getFormula (val) {
      if (val.indexOf('`') >= 0) {
        val = val.replaceAll('`', '')
        const parser = new AsciiMathParser()
        return parser.parse(val).replaceAll('∑', 'Σ')
      }
      return val
    },
    handleWidth (newWidth, oldWidth, column) {
      // this.options.width[column.property] = newWidth
    }
  }
}
</script>

<style scoped>

.main-table >>> .el-table__header-wrapper {
  /* need for sticky header */
  top: 50px;
  z-index: 1;
}

.el-table >>> .cell {
  line-height: 17px;
  font-size: 13px;
}

.el-table >>> .el-input__suffix {
  right: 10px;
}

.el-table >>> .row-warning {
  background: #ffeeba;
}

.el-table >>> .row-danger {
  background: #f5c6cb;
}

.el-table >>> .katex {
  font-size: 1.5em;
}

.el-table >>> .comment-exist {
  background-color: rgb(201, 164, 228) !important;
}

.el-table >>> .rating-e {
  background-color: rgb(255, 91, 91) !important;
}

.el-table >>> .rating-d {
  background-color: rgb(244, 177, 131) !important;
}

.el-table >>> .rating-c {
  background-color: rgb(255, 255, 153) !important;
}

.el-table >>> .rating-b {
  background-color: rgb(197, 224, 180) !important;
}

.el-table >>> .rating-a {
  background-color: rgb(146, 208, 80) !important;
}

.el-table >>> .row-bold {
  font-weight: bold;
}

.rating-cell {
  text-align: center;
}

.rating-cell > a + a {
  margin-left: 5px;
}
</style>
